<template>
  <div>
    <div class="container">
      <p style="font-weight: bold; text-align: center">
        FORMULARIO DENUNCIA POR PRESUNTOS ACTOS CORRUPCIÓN
      </p>
    </div>
    <div class="container">
      <p style="text-align: justify">
        Señor(a) usuario (a), los campos que tienen el * son obligatorios para
        radicar su solicitud. Le recomendamos diligenciar la mayoría o totalidad
        de la información solicitada.
      </p>
      <p style="text-align: justify">
        Estimado ciudadano, en este espacio Usted podrá informar a la
        Corporación Autónoma Regional del Valle del Cauca sobre situaciones que
        presuntamente puedan tratarse de hechos de corrupción cometidos por
        nuestros servidores públicos. Le invitamos a diligenciar el formulario
        de forma clara y en lo posible aportar las evidencias del caso. <br />

        Recuerde que este tipo de denuncias también podrá hacerlas a través de
        la línea #550 o en nuestras ventanilllas únicas a lo largo del Valle del
        Cauca. Conoce nuestras sedes haciendo clic
        <a
          href="https://www.cvc.gov.co/servicio-al-ciudadano/pqrds"
          target="_blank"
          >aquí</a
        >.
      </p>
      <p style="text-align: justify">
        <b>¿Qué se entiende por corrupción?</b><br />
        La corrupción política se refiere a los actos deshonestos o delictivos
        cometidos por funcionarios y autoridades públicas que abusan de su poder
        e influyen a realizar un mal uso intencional de los recursos financieros
        y humanos a los que tienen acceso, anticipando sus intereses personales
        o los de sus allegados, para conseguir una ventaja ilegítima
        generalmente de forma secreta y privada.
      </p>
      <p style="text-align: justify">
        <b>¿Quiénes son servidores públicos?</b><br />
        El artículo 123 de la Constitución política de Colombia establece: “Son
        servidores públicos los miembros de las corporaciones públicas, los
        empleados y trabajadores del Estado y de sus entidades descentralizadas
        territorialmente y por servicios.
      </p>
    </div>
    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="col-md-6" v-show="showanonimo">
        <b-form-group
          label="*Desea realizar su solicitud de forma anónima?"
          style="text-align: left"
        >
          <b-form-radio-group
            id="radio-slots"
            v-model="form.selectedanonimo"
            name="selectedanonimo"
          >
            <b-form-radio value="1">Si </b-form-radio>
            <b-form-radio value="2">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>

      <div
        style="text-align: left; margin-top: 20px"
        v-if="form.selectedanonimo == 2"
      >
        <label style="font-weight: bold">DATOS DEL SOLICITANTE </label>
      </div>
      <div class="row" v-if="form.selectedanonimo == 2">
        <div class="col-md-6" v-if="form.selectedanonimo == 2">
          <b-form-group label="*Tipo de persona:" style="text-align: left">
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoper"
              name="selectedtipoper"
              @change="onChangeRadioButton()"
            >
              <b-form-radio value="1">Natural </b-form-radio>
              <b-form-radio value="2">Jurídica</b-form-radio>
            </b-form-radio-group>
            <b-form-radio-group
              v-if="form.selectedtipoper == 2"
              id="radio-slots"
              v-model="form.selectedtipoperjuridica"
              name="selectedtipoperjuridica"
            >
              <b-form-radio value="1">Jurídica pública </b-form-radio>
              <b-form-radio value="2">Jurídica privada </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6" v-if="form.selectedanonimo == 2">
          <b-form-group
            label="*Tipo de identificación:"
            style="text-align: left"
          >
            <b-form-radio-group
              id="radio-slots"
              v-model="form.selectedtipoident"
              name="selectedtipoident"
            >
              <b-form-radio value="10" v-if="form.selectedtipoper == 1"
                >CC
              </b-form-radio>
              <b-form-radio value="26" v-if="form.selectedtipoper == 2"
                >NIT
              </b-form-radio>
              <b-form-radio value="36" v-if="form.selectedtipoper == 1"
                >Pasaporte
              </b-form-radio>
              <b-form-radio value="25" v-if="form.selectedtipoper == 2"
                >Personería jurídica
              </b-form-radio>
              <b-form-radio value="11" v-if="form.selectedtipoper == 1"
                >Cédula de extranjería
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12" v-if="form.selectedanonimo == 2">
          <b-form-group style="text-align: left">
            <label
              style="text-align: left"
              v-if="
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 1
              "
            >
              *Razón Social</label
            >
            <b-form-input
              v-if="
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 1
              "
              v-model="form.nombre"
              placeholder="razón social"
              maxlength="180"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12" v-if="form.selectedanonimo == 2">
          <b-form-group>
            <div class="row">
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Nombre</label
                >
                <b-form-input
                  placeholder="Primer Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre1"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Nombre</label
                >
                <b-form-input
                  placeholder="Segundo Nombre"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre2"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >*Primer Apellido</label
                >
                <b-form-input
                  placeholder="Primer Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre3"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
              <div class="col-md-3" style="text-align: left">
                <label
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  >Segundo Apellido</label
                >
                <b-form-input
                  placeholder="Segundo Apellido"
                  v-if="
                    form.selectedtipoident == 10 ||
                    form.selectedtipoident == 36 ||
                    form.selectedtipoident == 11
                  "
                  v-model="form.nombre4"
                  maxlength="180"
                  onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12" v-if="form.selectedanonimo == 2">
          <b-form-group style="text-align: left">
            <label
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 26 ||
                form.selectedtipoident == 25 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 1 ||
                form.selectedtipoident == 11
              "
              >*Número de Identificación
            </label>
            <b-form-input
              v-model="form.identificacion"
              placeholder="No identificación:"
              v-if="
                form.selectedtipoident == 10 ||
                form.selectedtipoident == 36 ||
                form.selectedtipoident == 11
              "
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="20"
              @input="validarNumero"
            ></b-form-input>
            <div class="row">
              <div class="col-md-9">
                <b-form-input
                  v-model="form.identificacion"
                  placeholder="No:"
                  v-if="
                    form.selectedtipoident == 26 ||
                    form.selectedtipoident == 25 ||
                    form.selectedtipoident == 1
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="20"
                  @input="validarNumero"
                ></b-form-input>
              </div>
              <div class="col-md-3">
                <b-form-input
                  v-model="form.digito"
                  placeholder="Dígito verificación:"
                  v-if="
                    form.selectedtipoident == 26 ||
                    form.selectedtipoident == 25 ||
                    form.selectedtipoident == 1
                  "
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  type="number"
                  maxlength="1"
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div>
        <div
          class="col-md-12"
          v-if="form.selectedanonimo == 1 || form.selectedanonimo == 2"
        >
          <b-form-group
            label="*Dirección de correspondencia:"
            style="text-align: left"
            ><b-form-input
              v-model="form.dircorrespondencia"
              placeholder="Dirección de correspondencia"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6" v-if="form.selectedanonimo !== ''">
          <b-form-group label="*Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.depart"
              :options="optionsdepartamento"
              @change="onChangeCiudad()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Ciudad:" style="text-align: left">
            <b-form-select v-show="showciudad" v-model="ciudadcompleta">
              <b-form-select-option
                :key="ind"
                v-for="(tr, ind) in optionsciudades"
                :value="tr"
              >
                {{ tr.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Teléfono (s):" style="text-align: left"
            ><b-form-input
              v-model="form.tel"
              placeholder="Teléfono (s)"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Fax:" style="text-align: left"
            ><b-form-input
              v-model="form.fax"
              placeholder="Fax"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="35"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="*¿Autoriza la notificación mediante correo electrónico?"
            style="text-align: left"
            ><b-form-radio-group
              id="radio-slots"
              v-model="form.selectedcorreo"
              name="selectedcorreo"
            >
              <b-form-radio value="1">Si </b-form-radio>
              <b-form-radio value="2">No </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="En caso contrario indique la dirección para notificación física:"
            style="text-align: left"
            ><b-form-input
              v-model="form.correodirnoti"
              placeholder="Correo electrónico o dirección de notificación"
              maxlength="50"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!------------------------------------------------------------------------------------->

      <!--------------------------cierre row finaliza el primer bloque de informacion ------------->
    </div>
    <br />
    <!--<div class="border border-dark container" style="background: #f5f5f5">
      <div style="text-align: left; margin-top: 20px">
        <label style="font-weight: bold"
          >II. UBICACÍON DEL PROYECTO, OBRA O ACTIVIDAD
        </label>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="1. Nombre del predio:" style="text-align: left"
            ><b-form-input
              v-model="form.nombrepredio"
              placeholder="Nombre del predio"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="2. *Dirección del predio:"
            style="text-align: left"
            ><b-form-input
              v-model="form.direccionpredio"
              placeholder="Dirección del predio"
              maxlength="95"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="3. *Departamento:" style="text-align: left">
            <b-form-select
              v-model="form.departpredio"
              :options="optionsdepart"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="*Municipio:" style="text-align: left">
            <b-form-select
              v-model="form.ciudadpredio"
              :options="optionsCiudad"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Nombre centro poblado, vereda y/o corregimiento:"
            style="text-align: left"
            ><b-form-input
              v-model="form.nombrecentropoblado"
              placeholder="Nombre centro poblado, vereda y/o corregimiento:"
              maxlength="300"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <br />
    <div
      class="border border-dark container"
      style="background: #f5f5f5"
      v-if="showbloquedatos"
    >
      <b-row class="my-1">
        <b-col sm="6">
          <label for="input-default"
            >Ingrese los días hábiles en los que se requiere la respuesta</label
          >
        </b-col>
        <b-col sm="3">
          <b-form-group style="text-align: left"
            ><b-form-input
              v-model="form.diashabiles"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
              type="number"
              maxlength="2"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <br />-->

    <div class="border border-dark container" style="background: #f5f5f5">
      <div class="row" style="padding: 5px">
        <div class="col-md-12">
          <b-form-group
            label="Listado de presuntos actos de corrupción"
            style="text-align: left"
          >
            <b-dropdown
              id="dropdown-tipodenun"
              class="w-100"
              :text="dropdownText"
              variant="primary"
            >
              <b-dropdown-item
                v-for="option in optionsdenuncia"
                :key="option.value"
                @click="selectOption(option)"
                :active="`${option.value} - ${option.text}` === form.tipodenun"
              >
                <span v-b-tooltip.hover :title="option.title">{{
                  option.text
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group
            label="* Descripción de los hechos"
            style="text-align: left; font-weight: bold"
            ><b-form-textarea
              v-model="form.observacionradicado"
              maxlength="3900"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="col-md-12" v-if="form.selectedanonimo == 1">
          <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            value="accepted"
          >
            ¿Autoriza que las comunicaciones se le envíen mediante correo
            electrónico?
          </b-form-checkbox>
        </div>
        <div class="col-md-12" v-if="status === 'accepted'">
          <b-form-group label="Correo electrónico:" style="text-align: left"
            ><b-form-input
              v-model="form.correo1"
              placeholder="Correo electrónico"
              type="email"
              class="form-control"
              maxlength="55"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-11">
          <b-form-file
            v-model="file1"
            :state="Boolean(file1)"
            placeholder="Adjunta las pruebas, peso maximo total archivos 50 Mb, peso maximo por cada archivo 10 Mb"
            drop-placeholder="Drop file here..."
            multiple
            browse-text="Buscar"
          ></b-form-file>
        </div>
        <div class="col-md-1">
          <b-button id="popover-button-variant" href="#" tabindex="0"
            >Ayuda</b-button
          >
          <b-popover
            target="popover-button-variant"
            variant="info"
            triggers="focus"
          >
            <template #title>Tutorial</template>
            De click
            <b-link
              href="../../../documentos/Guía Rápida Adjuntar documentos Tramites Web.pdf"
              target="blank"
              >aqui</b-link
            >
          </b-popover>
        </div>
      </div>

      <br /><br />
      <b-form-checkbox
        id="aceptacion"
        v-model="form.aceptacion"
        name="aceptacion"
        value="1"
        unchecked-value="0"
        >Acepto el uso de mis datos personales, tratamiento, protección y
        conservación de estos a CVC de acuerdo a lo establecido en la Ley 1581
        de 2012.<br />
        Al hacer clic el botón enviar, usted acepta la remisión de la PQRS a la
        CVC. Sus datos serán recolectados y tratados conforme con la Política de
        Tratamiento de Datos.
        <a
          href="https://www.cvc.gov.co/politicas_de_seguridad_y_privacidad_de_la_informacion"
          >Ver Política de protección de Datos Personales de la CVC.</a
        >
        En la opción consulta de
        <a href="https://consultaweb.cvc.gov.co/">PQRS </a> podrá verificar el
        estado de la respuesta.<br />
        En caso que la solicitud de información sea de naturaleza de identidad
        reservada, deberá efectuar el respectivo trámite ante la Procuraduría
        General de la Nación, haciendo clic en el siguiente
        <a
          href="https://www.procuraduria.gov.co/portal/solicitud_informacion_identificacion_reservada.page"
          >link:</a
        > </b-form-checkbox
      ><br />
      <div v-show="show">
        <b-button block variant="success" @click="enviar()"
          >Enviar solicitud</b-button
        >
      </div>
      <br />
      <!-- nuevo codgio-->
      <div class="text-center" v-show="loading">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <div class="text-center">
          <strong>Enviando Solicitud</strong><br />
          <strong
            >IMPORTANTE: Si adjuntó archivos, por favor espere que termine la
            subida y salga un mensaje con el número de radicado. Si abandona el
            proceso, la solicitud quedará incompleta.....</strong
          >
        </div>
        <br />

        <div
          :key="ind"
          v-for="(tr, ind) in filesValide"
          class="row ml-8 mb-2 d-flex justify-content-center"
        >
          <b-progress
            :max="100"
            striped
            animated
            :variant="'primary'"
            class="w-75"
          >
            <b-progress-bar :value="tr.value === 0 ? 33 : tr.value">
              <span>
                {{
                  tr.value !== 0
                    ? tr.color
                      ? `Se proceso el archivo '${tr.name} de manera correcta.`
                      : `No se pudo procesar el archivo '${tr.name}'.`
                    : `Se esta procesando el archivo '${tr.name}'`
                }}
                <strong></strong></span
            ></b-progress-bar>
          </b-progress>
        </div>
      </div>
      <!-- cierre nuevo codgio-->
      <br />
    </div>

    <br />
  </div>
  <!--cierre div principal-->
</template>

<script>
import {
  rutafile,
  rutaciudad,
  rutaenviosolicitud,
  tiponotificacion,
  logo,
} from "../properties";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      form: {
        selectedanonimo: "",
        tipotramite: "90",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "DENUNCIA POR CORRUPCIÓN",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "355", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        desciudad: "", //CAMBIO
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: tiponotificacion,
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        desciudadapoderado: "", //CAMBIO
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        aceptacion: "",
        nombrepredio: "",
        direccionpredio: "-1",
        departpredio: "-1",
        ciudadpredio: "-1",
        nombrecentropoblado: "",
        tipodenun: "",
        logo: logo,
        filesname: "",
        correo1: "",
      },
      status: "",
      ciudadapoderado: "", //CAMBIO
      ciudadcompleta: "", //CAMBIO
      loading: false,
      show: true,
      showciudad: false,
      showciudadapo: false,
      showanonimo: false,
      showbloque: false,
      showbloquedatos: false,
      file1: [],
      pesofile1: 0,
      nombrefiles: [],
      arryaJson: [],
      contadorarchivos: 0, // nueva
      contadorresponse: 0, // nueva
      filesValide: [], // nueva
      optionsCiudad: [
        { value: "1000", text: "SANTIAGO DE CALI" },
        { value: "20000", text: "ALCALA" },
        { value: "36000", text: "ANDALUCIA" },
        { value: "41000", text: "ANSERMANUEVO" },
        { value: "54000", text: "ARGELIA" },
        { value: "100000", text: "BOLIVAR" },
        { value: "109000", text: "BUENAVENTURA" },
        { value: "111000", text: "GUADALAJARA DE BUGA" },
        { value: "113000", text: "BUGALAGRANDE" },
        { value: "122000", text: "CAICEDONIA" },
        { value: "126000", text: "DARIEN" },
        { value: "130000", text: "CANDELARIA" },
        { value: "147000", text: "CARTAGO" },
        { value: "233000", text: "DAGUA" },
        { value: "243000", text: "EL AGUILA" },
        { value: "246000", text: "EL CAIRO" },
        { value: "248000", text: "EL CERRITO" },
        { value: "250000", text: "EL DOVIO" },
        { value: "275000", text: "FLORIDA" },
        { value: "306000", text: "GINEBRA" },
        { value: "318000", text: "GUACARI" },
        { value: "364000", text: "JAMUNDI" },
        { value: "377000", text: "LA CUMBRE" },
        { value: "400000", text: "LA UNION" },
        { value: "403000", text: "LA VICTORIA" },
        { value: "497000", text: "OBANDO" },
        { value: "520000", text: "PALMIRA" },
        { value: "563000", text: "PRADERA" },
        { value: "606000", text: "RESTREPO" },
        { value: "616000", text: "RIOFRIO" },
        { value: "622000", text: "ROLDANILLO" },
        { value: "670000", text: "SAN PEDRO" },
        { value: "736000", text: "SEVILLA" },
        { value: "823000", text: "TORO" },
        { value: "828000", text: "TRUJILLO" },
        { value: "834000", text: "TULUA" },
        { value: "845000", text: "ULLOA" },
        { value: "863000", text: "VERSALLES" },
        { value: "869000", text: "VIJES" },
        { value: "890000", text: "YOTOCO" },
        { value: "892000", text: "YUMBO" },
        { value: "895000", text: "ZARZAL" },
      ],
      optionsdepart: [{ value: "76", text: "VALLE DEL CAUCA" }],
      optionsdepartamento: [
        { value: "91", text: "AMAZONAS" },
        { value: "5", text: "ANTIOQUIA" },
        { value: "81", text: "ARAUCA" },
        {
          value: "88",
          text: "ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        },
        { value: "8", text: "ATLANTICO" },
        { value: "11", text: "BOGOTA D.C" },
        { value: "13", text: "BOLIVAR" },
        { value: "15", text: "BOYACA" },
        { value: "17", text: "CALDAS" },
        { value: "18", text: "CAQUETA" },
        { value: "85", text: "CASANARE" },
        { value: "19", text: "CAUCA" },
        { value: "20", text: "CESAR" },
        { value: "27", text: "CHOCO" },
        { value: "23", text: "CORDOBA" },
        { value: "25", text: "CUNDINAMARCA" },
        { value: "94", text: "GUAINIA" },
        { value: "95", text: "GUAVIARE" },
        { value: "41", text: "HUILA" },
        { value: "44", text: "LA GUAJIRA" },
        { value: "47", text: "MAGDALENA" },
        { value: "50", text: "META" },
        { value: "52", text: "NARIÑO" },
        { value: "54", text: "NORTE DE SANTANDER" },
        { value: "86", text: "PUTUMAYO" },
        { value: "63", text: "QUINDIO" },
        { value: "66", text: "RISARALDA" },
        { value: "68", text: "SANTANDER" },
        { value: "70", text: "SUCRE" },
        { value: "73", text: "TOLIMA" },
        { value: "76", text: "VALLE DEL CAUCA" },
        { value: "97", text: "VAUPES" },
        { value: "99", text: "VICHADA" },
      ],
      optionsciudades: [],
      optionstiponoti: [
        { value: "2", text: "CORREO CERTIFICADO" },
        { value: "1", text: "CORREO ELECTRONICO" },
        { value: "7", text: "NOTIFICACIÓN PERSONAL" },
      ],
      selectedOption: null,
      optionsdenuncia: [
        { value: "1", text: "Peculado" },
        {
          value: "2",
          text: "Peculado por Apropiación",
          title:
            "El servidor público que se apropie en provecho suyo o de un tercero de bienes del Estado o de empresas o instituciones en que éste tenga parte o de bienes o fondos parafiscales, o de bienes de particulares cuya administración, tenencia o custodia se le haya confiado por razón o con ocasión de sus funciones.",
        },
        {
          value: "3",
          text: "Peculado por uso Artículo 398 del Código Penal",
          title:
            "El servidor público que indebidamente use o permita que otro use bienes del Estado o de empresas o instituciones en que éste tenga parte, o bienes de particulares cuya administración, tenencia o custodia se le haya confiado por razón o con ocasión de sus funciones.",
        },
        {
          value: "4",
          text: "Peculado por aplicación oficial diferente",
          title:
            "El servidor público que dé a los bienes del Estado o de empresas o instituciones en que éste tenga parte, cuya administración, tenencia o custodia se le haya confiado por razón o con ocasión de sus funciones, aplicación oficial diferente de aquella a que están destinados, o comprometa sumas superiores a las fijadas en el presupuesto, o las invierta o utilice en forma no prevista en éste, en perjuicio de la inversión social o de los salarios o prestaciones sociales de los servidores.",
        },
        {
          value: "5",
          text: "Peculado culposo",
          title:
            "El servidor público que respecto a bienes del Estado o de empresas o instituciones en que éste tenga parte, o bienes de particulares cuya administración, tenencia o custodia se le haya confiado por razón o con ocasión de sus funciones, por culpa dé lugar a que se extravíen, pierdan o dañen.",
        },
        {
          value: "6",
          text: "Concusión",
          title:
            "El servidor público que abusando de su cargo o de sus funciones constriña o induzca a alguien a dar o prometer al mismo servidor o a un tercero, dinero o cualquier otra utilidad indebidos, o los solicite.",
        },
        { value: "7", text: "Cohecho" },
        {
          value: "8",
          text: "Cohecho propio",
          title:
            "El servidor público que reciba para sí o para otro, dinero u otra utilidad, o acepte promesa remuneratoria, directa o indirectamente, para retardar u omitir un acto propio de su cargo, o para ejecutar uno contrario a sus deberes oficiales.",
        },
        {
          value: "9",
          text: "Cohecho impropio",
          title:
            "El servidor público que acepte para sí o para otro, dinero u otra utilidad o promesa remuneratoria, directa o indirecta, por acto que deba ejecutar en el desempeño de sus funciones. El servidor público que reciba dinero u otra utilidad de persona que tenga interés en asunto sometido a su conocimiento. Artículo 406 del Código Penal. Modificado por el artículo 33 de la Ley 1474 de 2011",
        },
        {
          value: "10",
          text: "Cohecho por dar u ofrecer",
          title:
            "El que dé u ofrezca dinero u otra utilidad a servidor público, en los casos previstos en los dos artículos anteriores.",
        },
        { value: "11", text: "Contratación" },
        {
          value: "12",
          text: "Violación del régimen legal o constitucional de inhabilidades e incompatibilidades",
          title:
            "El servidor público que en ejercicio de sus funciones intervenga en la tramitación, aprobación o celebración de un contrato con violación al régimen legal o a lo dispuesto en normas constitucionales, sobre inhabilidades o incompatibilidades.",
        },
        {
          value: "13",
          text: "Interés indebido en la celebración de contratos",
          title:
            "El servidor público que se interese en provecho propio o de un tercero, en cualquier clase de contrato u operación en que deba intervenir por razón de su cargo o de sus funciones. Artículo 409 del Código Penal.",
        },
        {
          value: "14",
          text: "Contrato sin cumplimiento de requisitos legales",
          title:
            "El servidor público que por razón del ejercicio de sus funciones tramite contrato sin observancia de los requisitos legales esenciales o lo celebre o liquide sin verificar el cumplimiento de estos.",
        },
        {
          value: "15",
          text: "Acuerdos restrictivos de la competencia",
          title:
            "El que en un proceso de licitación pública, subasta pública, selección abreviada o concurso se concertare con otro con el fin de alterar ilícitamente el procedimiento contractual.",
        },
        {
          value: "16",
          text: "Tráfico de influencias de servidor público",
          title:
            "Servidor público que utilice indebidamente, en provecho propio o de un tercero, influencias derivadas del ejercicio del cargo o de la función, con el fin de obtener cualquier beneficio de parte de servidor público en asunto que éste se encuentre conociendo o haya de conocer.",
        },
        {
          value: "17",
          text: "Tráfico de influencias de particular",
          title:
            "El particular que ejerza indebidamente influencias sobre un servidor público en asunto que este se encuentre conociendo o haya de conocer, con el fin de obtener cualquier beneficio económico.",
        },
        {
          value: "18",
          text: "Enriquecimiento ilícito",
          title:
            "El servidor público, o quien haya desempeñado funciones públicas, que durante su vinculación con la administración o dentro de los cinco (5) años posteriores a su desvinculación, obtenga, para sí o para otro, incremento patrimonial injustificado, incurrirá, siempre que la conducta no constituya otro delito.",
        },
        { value: "19", text: "Prevaricato" },
        {
          value: "20",
          text: "Prevaricato por acción",
          title:
            "El servidor público que profiera resolución, dictamen o concepto manifiestamente contrario a la ley.",
        },
        {
          value: "21",
          text: "Prevaricato por omisión",
          title:
            "El servidor público que omita retarde, rehusé o deniegue un acto propio de sus funciones.",
        },
        {
          value: "22",
          text: "Utilización de asunto sometido a secreto o reserva",
          title:
            "El servidor público que utilice en provecho propio o ajeno, descubrimiento científico, u otra información o dato llegados a su conocimiento por razón de sus funciones y que deban permanecer en secreto o reserva.",
        },
        {
          value: "23",
          text: "Utilización indebida de información oficial privilegiada",
          title:
            "El servidor público que, como empleado o directivo o miembro de una junta u órgano de administración de cualquier entidad pública, que haga uso indebido de información que haya conocido por razón o con ocasión de sus funciones y que no sea objeto de conocimiento público, con el fin de obtener provecho para sí o para un tercero, sea éste persona natural o jurídica.",
        },
        {
          value: "24",
          text: "Intervención en Política",
          title:
            "El servidor público que ejerza jurisdicción, autoridad civil o política, cargo de dirección administrativa, o se desempeñe en los órganos judicial, electoral, de control, que forme parte de comités, juntas o directorios políticos, o utilice su poder para favorecer o perjudicar electoralmente a un candidato, partido o movimiento político.",
        },
        {
          value: "25",
          text: "Obtención de documento público falso",
          title:
            "El que para obtener documento público que pueda servir de prueba, induzca en error a un servidor público, en ejercicio de sus funciones, haciéndole consignar una manifestación falsa o callar total o parcialmente la verdad, incurrirá en prisión de tres (3) a seis (6) años.",
        },
        {
          value: "26",
          text: "Falsedad en documento público",
          title:
            "El servidor público que, en ejercicio de sus funciones, al extender documento público que pueda servir de prueba, consigne una falsedad o calle total o parcialmente la verdad, incurrirá en prisión de cuatro (4) a ocho (8) años e inhabilitación para el ejercicio de derechos y funciones públicas de cinco (5) a diez (10) años.",
        },
        {
          value: "27",
          text: "Falsedad ideológica en documento público",
          title:
            "El servidor público que, en ejercicio de sus funciones, al extender documento público que pueda servir de prueba, consigne una falsedad o calle total o parcialmente la verdad, incurrirá en prisión de cuatro (4) a ocho (8) años e inhabilitación para el ejercicio de derechos y funciones públicas de cinco (5) a diez (10) años.",
        },
        {
          value: "28",
          text: "Falsedad material en documento público",
          title:
            "El que falsifique documento público que pueda servir de prueba, incurrirá en prisión de tres (3) a seis (6) años.",
        },
        {
          value: "29",
          text: "Falsedad personal",
          title:
            "El que con el fin de obtener un provecho para sí o para otro o causar daño, sustituya o suplante a una persona o se atribuya nombre, edad, estado civil o calidad que pueda tener efectos jurídicos, incurrirá en multa, siempre que la conducta no constituya otro delito.",
        },
        { value: "30", text: "Abuso de autoridad" },
        {
          value: "31",
          text: "Abuso de autoridad por acto arbitrario e injusto",
          title:
            "El servidor público que fuera de los casos especialmente previstos como conductas punibles, con ocasión de sus funciones o excediéndose en el ejercicio de ellas, cometa acto arbitrario e injusto, incurrirá en multa y pérdida del empleo o cargo público.",
        },
        {
          value: "32",
          text: "Abuso de autoridad por omisión de denuncia",
          title:
            "El servidor público que teniendo conocimiento de la comisión de una conducta punible cuya averiguación deba adelantarse de oficio, no dé cuenta a la autoridad, incurrirá en multa y pérdida del empleo o cargo público",
        },
        {
          value: "33",
          text: "Asesoramiento y otras actuaciones ilegales",
          title:
            "El servidor público que ilegalmente represente, litigue, gestione o asesore en asunto judicial, administrativo o policivo, incurrirá en multa y pérdida del empleo o cargo público. Si el responsable fuere servidor de la rama judicial o del Ministerio Público la pena será de prisión de dieciséis (16) a cincuenta y cuatro (54) meses, e inhabilitación para el ejercicio de derechos y funciones públicas por ochenta (80) meses",
        },
        { value: "34", text: "Falsedad" },
        {
          value: "35",
          text: "Falsedad Personal",
          title:
            "El que con el fin de obtener un provecho para sí o para otro, o causar daño, sustituya o suplante a una persona o se atribuya nombre, edad, estado civil o calidad que pueda tener efectos jurídicos, incurrirá en multa, siempre que la conducta no constituya otro delito.",
        },
        { value: "36", text: "Usurpación" },
        {
          value: "37",
          text: "Simulación de Investidura o Cargo",
          title:
            "El que simulare investidura o cargo público o fingiere pertenecer a la fuerza pública, (…). En la misma pena incurrirá el que con fines ilícitos porte o utilice uniformes o distintivos de una persona jurídica.",
        },
        {
          value: "38",
          text: "Extorsión",
          title:
            "El que constriña a otro a hacer, tolerar u omitir alguna cosa, con el propósito de obtener provecho ilícito o cualquier utilidad ilícita o beneficio ilícito, para sí o para un tercero",
        },
        { value: "39", text: "Abuso de Confianza" },
        {
          value: "40",
          text: "Abuso de Confianza Calificado",
          title:
            "El que se apropie en provecho suyo o de un tercero, de cosa mueble ajena, que se le haya confiado o entregado por un título no traslativo de dominio, (…). Calificado artículo 250, numeral 3: Sobre bienes pertenecientes a empresas o instituciones en que el Estado tenga la totalidad o la mayor parte, o recibidos a cualquier título de éste.",
        },
        { value: "41", text: "Otro" },
      ],
      dropdownText: "Selecciona una opción",
    };
  },
  watch: {
    "form.observacionradicado": function (newVal) {
      // Reemplaza el carácter "&" por una cadena vacía
      this.form.observacionradicado = newVal
        .replace(/&/g, "")
        .replace(/'/g, "");
    },
    "form.tipodenun": function (newVal) {
      const selectedOption = this.optionsdenuncia.find(
        (option) => `${option.value} - ${option.text}` === newVal
      );
      this.dropdownText = selectedOption
        ? selectedOption.text
        : "Selecciona una opción";
    },
  },
  methods: {
    selectOption(option) {
      this.form.tipodenun = `${option.value} - ${option.text}`;
      this.dropdownText = option.text;
    },
    enviar() {
      this.arryaJson = [];
      this.nombrefiles = [];
      //console.log("this.form.identificacion ", this.form.identificacion);
      if (this.form.selectedanonimo == 1) {
        this.form.selectedtipoper = "-1";
        this.form.selectedtipoident = "-1";
        this.form.identificacion = "-1";
        (this.form.nombre = ""),
          (this.form.nombre1 = ""),
          (this.form.nombre2 = ""),
          (this.form.nombre3 = ""),
          (this.form.nombre4 = ""),
          (this.form.digito = ""),
          (this.form.dircorrespondencia = ""),
          (this.form.ciudad = ""),
          (this.form.depart = ""),
          (this.form.tel = ""),
          (this.form.fax = ""),
          (this.form.correo = "");
      }

      if (this.form.selectedtipoident === "25") {
        this.form.selectedtipoident = "26";
        console.log("this.form.selectedtipoident", this.form.selectedtipoident);
      }

      if (this.file1.length > 0) {
        this.file1.forEach((file) => {
          this.nombrefiles.push(file.name);
        });
      }
      this.form.filesname = this.nombrefiles.toString();

      this.form.desciudad = this.ciudadcompleta.text; //CAMBIO
      this.form.ciudad = this.ciudadcompleta.value; //CAMBIO

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (this.validacioncampos(this.form) === true) {
        this.arryaJson.push(this.form);
        console.log("this.arryaJson 769 ", this.arryaJson);

        if (Array.isArray(this.arryaJson)) {
          // Recorre el arryaJson y aplica la función a todas las propiedades de todos los objetos en la matriz
          this.arryaJson.forEach((obj) => {
            for (const prop in obj) {
              if (typeof obj[prop] === "string") {
                obj[prop] = this.replaceSpecialCharacters(obj[prop]);
              }
            }
          });
        } else {
          console.error("arryaJson no es una matriz o está indefinida");
        }

        console.log("this.arryaJson 779 ", this.arryaJson);

        this.loading = true;
        this.show = false;

        this.pesofile1 = 0;
        for (var i = 0; i < this.file1.length; i++) {
          this.pesofile1 += this.file1[i].size;
        }
        //console.log('this.pesofile1 ',this.pesofile1)

        // VALIDO SI CARGARON ARCHIVOS ADJUNTOS ----------------->
        if (this.file1.length > 0) {
          if (this.validarpesofile(this.file1) === true) {
            //console.log("valida archivos", this.validarpesofile(this.file1));
            // VALIDO SI TODOS LOS ARCHIVOS ADJUNTOS SUPERAN LOS 50 MB ----------------->
            if (this.pesofile1 > 50000000) {
              swal(
                "El peso total de los archivos adjuntos supera los 50 Mb permitidos"
              );
              this.loading = false;
              this.show = true;
              //console.log('tamaño de los archivos -------- ',this.pesofile1)
              this.pesofile1 = 0;
            } else if (this.pesofile1 < 1024) {
              swal(
                "El peso de uno o más archivos adjuntos es menor a 1 KB, lo cual no es permitido."
              );
              this.loading = false;
              this.show = true;
              this.pesofile1 = 0;
              console.log("tamaño de los archivos -------- ", this.pesofile1);
            } else {
              // SE ENVIA LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
              axiosInstance
                .post(rutaenviosolicitud, this.arryaJson)
                .then((response) => {
                  let blob = this.b64toBlob(response.data.pdf);
                  console.log("adjuntos blob ", blob);

                  this.file1.push(blob);

                  //console.log(response.data);
                  //console.log("adjuntos ", this.file1.length);
                  if (this.file1.length > 0) {
                    this.contadorarchivos = this.file1.length;
                    this.filesValide = []; /////////////7nuevo

                    console.log("cantidad de archivos ", this.contadorarchivos);
                    this.file1.forEach((file) => {
                      /////////////7nuevo
                      this.filesValide.push({
                        name: file.name,
                        value: 0,
                        color: true,
                      });

                      const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                      const formData = new FormData();
                      var regex = /([^-\w.]*)/gm;
                      //console.log("nombre archivo", file.name);
                      var arcPDF2 = file.name.replace(regex, "");
                      arcPDF2 = arcPDF2.replaceAll(" ", "");
                      //console.log("nombre nuevo ", arcPDF2);

                      var blob = file.slice(0, file.size, file.type);
                      var newFile = new File([blob], arcPDF2, {
                        type: file.type,
                      });

                      formData.append("file", newFile);
                      formData.append("fileName", arcPDF2);
                      formData.append("radicado", response.data.radicado);
                      formData.append("usuario", response.data.usuario);
                      formData.append("ordenTrabajo", response.data.orden);
                      formData.append("idCorreo", response.data.correo);

                      // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                      axios
                        .post(rutafile, formData, {
                          /// envio archivos
                          headers: { "content-type": "multipart/form-data" },
                          maxRedirects: 0,
                        })
                        .then((respon) => {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          if (respon.status === 200) {
                            this.contadorresponse++;
                          } else {
                            ///////////77nuevo
                            this.filesValide[keyInd].color = false;
                          } ///////////77nuevo

                          this.resetForm();
                          //console.log("Respuesta envio adjuntos", respon);

                          if (this.contadorarchivos === this.contadorresponse) {
                            this.loading = false;
                            swal({
                              closeOnClickOutside: false,
                              text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                          }

                          this.show = true;
                        })
                        .catch(function (error) {
                          this.filesValide[keyInd].value = 100; ///////////77nuevo
                          this.filesValide[keyInd].color = false; ///////////77nuevo
                          if (
                            error.response.status === 500 ||
                            error.response.status === 404
                          ) {
                            swal({
                              closeOnClickOutside: false,
                              text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                            }).then((value) => {
                              if (value) {
                                window.location.href = "https://cvc.gov.co/";
                              }
                            });
                            this.resetForm();
                            console.log("error 2022 ", error.response.status);
                          }
                          this.show = true;
                        });
                    });
                  } else {
                    this.resetForm();
                    swal({
                      closeOnClickOutside: false,
                      text: `...Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                    this.loading = false;
                    this.show = true;
                  }
                })
                .catch(function (error) {
                  if (error.response.status === 500) {
                    swal({
                      closeOnClickOutside: false,
                      text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                    }).then((value) => {
                      if (value) {
                        window.location.href = "https://cvc.gov.co/";
                      }
                    });
                  }
                  this.loading = false;
                  this.show = true;
                });
            }
          } else {
            swal("El peso de uno o mas archivos supera los 10 Mb permitidos");
            this.loading = false;
            this.show = true;
          }
        } else {
          // CUANDO NO CARGAR ARCHIVOS SE ENVIA SOLO LA SOLICITUD PARA GENERAR EL RADICADO ----------------->
          axiosInstance
            .post(rutaenviosolicitud, this.arryaJson)
            .then((response) => {
              let blob = this.b64toBlob(response.data.pdf);
              console.log("adjuntos blob ", blob);

              this.file1.push(blob);

              //console.log(response.data);
              //console.log("adjuntos ", this.file1.length);

              if (this.file1.length > 0) {
                this.contadorarchivos = this.file1.length;
                this.filesValide = []; /////////////7nuevo

                this.file1.forEach((file) => {
                  /////////////7nuevo
                  this.filesValide.push({
                    name: file.name,
                    value: 0,
                    color: true,
                  });

                  const keyInd = this.filesValide.length - 1; ////////////////7 fin nuevo
                  const formData = new FormData();
                  let regex = /([^-\w.]*)/gm;

                  let arcPDF2 = file.name.replace(regex, "");
                  arcPDF2 = arcPDF2.replaceAll(" ", "");

                  let blob = file.slice(0, file.size, file.type);
                  let newFile = new File([blob], arcPDF2, {
                    type: file.type,
                  });

                  formData.append("file", newFile);
                  formData.append("fileName", arcPDF2);
                  formData.append("radicado", response.data.radicado);
                  formData.append("usuario", response.data.usuario);
                  formData.append("ordenTrabajo", response.data.orden);
                  formData.append("idCorreo", response.data.correo);

                  // SE ENVIAN A GUARDAR LOS ARCHIVOS UNO A UNO ----------------->
                  axios
                    .post(rutafile, formData, {
                      /// envio archivos
                      headers: { "content-type": "multipart/form-data" },
                      maxRedirects: 0,
                    })
                    .then((respon) => {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      if (respon.status === 200) {
                        this.contadorresponse++;
                      } else {
                        ///////////77nuevo
                        this.filesValide[keyInd].color = false;
                      } ///////////77nuevo

                      this.resetForm();

                      if (this.contadorarchivos === this.contadorresponse) {
                        this.loading = false;
                        swal({
                          closeOnClickOutside: false,
                          text: `.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                      }

                      this.show = true;
                    })
                    .catch(function (error) {
                      this.filesValide[keyInd].value = 100; ///////////77nuevo
                      this.filesValide[keyInd].color = false; ///////////77nuevo
                      if (
                        error.response.status === 500 ||
                        error.response.status === 404
                      ) {
                        swal({
                          closeOnClickOutside: false,
                          text: `..Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}, pero hubo un error al guardar los documentos adjuntos por favor comuniquese con atención al ciudadano`,
                        }).then((value) => {
                          if (value) {
                            window.location.href = "https://cvc.gov.co/";
                          }
                        });
                        this.resetForm();
                        console.log("error 2022 ", error.response.status);
                      }
                      this.show = true;
                    });
                });
              }

              this.resetForm();
              swal({
                closeOnClickOutside: false,
                text: `.,.Señor(a) usuario(a) su solicitud fue registrada y le genero el radicado N°: ${response.data.radicado}`,
              }).then((value) => {
                if (value) {
                  window.location.href = "https://cvc.gov.co/";
                }
              });
              this.loading = false;
              this.show = true;
            })
            .catch(function (error) {
              if (error.response.status === 500) {
                swal({
                  closeOnClickOutside: false,
                  text: "Señor(a) usuario(a) el envio de su solicitud Fallo Intente de nuevo mas tarde",
                }).then((value) => {
                  if (value) {
                    window.location.href = "https://cvc.gov.co/";
                  }
                });
              }
              this.loading = false;
              this.show = true;
            });
        }
      } else {
        console.log("fallo");
      }
    },
    validarpesofile(files) {
      var count = 0;
      //console.log(files.length);
      files.forEach((file) => {
        if (file.size > 10000000) {
          count++;
        }
      });
      //console.log("archivos con peso de mas de 10 mb", count);
      if (count == 0) {
        return true;
      } else {
        return false;
      }
    },
    resetForm() {
      this.form = {
        tipotramite: "",
        mediorecepcion: "4",
        observacionradicado: "",
        asunto: "DENUNCIA POR CORRUPCIÓN",
        tiporadicado: "E",
        usuario: "arqu",
        idusuaasignado: "-1",
        pais: "1",
        selectedtipoper: "",
        selectedtipoperjuridica: "",
        selectedtipotram: "355", //si este es igual a 12 el datoadicional debe ser igual a null de lo contrario el datoadicional va a ser igual a 12
        nombre: "",
        nombre1: "",
        nombre2: "",
        nombre3: "",
        nombre4: "",
        selectedtipoident: "",
        identificacion: "",
        digito: "",
        dircorrespondencia: "",
        ciudad: "",
        depart: "",
        tel: "",
        fax: "",
        correo: "",
        selectedcorreo: "",
        correodirnoti: "",
        selectedtiponoti: "",
        selectedrepre: "",
        selectedtipoperapo: "",
        selectedtipoperjuridicaapo: "",
        nombreapoderado: "",
        nombre1apo: "",
        nombre2apo: "",
        nombre3apo: "",
        nombre4apo: "",
        selectedtipoidentapo: "",
        identificacionapode: "",
        digitoapo: "",
        lugaridapoderado: "",
        dirapoderado: "",
        ciudadapoderado: "",
        departapoderado: "",
        telapoderado: "",
        faxapoderado: "",
        correoapoderado: "",
        selectedcalidadactua: "",
        nombrepredio: "",
        direccionpredio: "",
        departpredio: "",
        ciudadpredio: "",
        nombrecentropoblado: "",
      };
    },
    b64toBlob(b64Data) {
      const names = "formulario.pdf";
      const newbase64 = "data:application/pdf;base64," + b64Data;

      let arr = newbase64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file_created = new File([u8arr], names, { type: mime });
      return file_created;
    },
    validarNumero() {
      this.form.identificacion = this.form.identificacion.replace(
        /[^0-9]/g,
        ""
      ); // Elimina cualquier carácter que no sea un número
    },
    validacioncampos() {
      if (this.form.selectedanonimo === "") {
        swal("Debe seleccionar el tipo de la solicitud");
      } else {
        if (
          this.form.selectedanonimo == 2 // si NO es anonimo
        ) {
          if (this.form.selectedtipoper === "") {
            swal("Debe seleccionar el tipo de persona");
          } else if (
            this.form.selectedtipoper === "2" &&
            this.form.selectedtipoperjuridica === ""
          ) {
            swal("Debe seleccionar el tipo de persona juridica");
          } else if (this.form.selectedtipoident === "") {
            swal("Debe seleccionar el tipo de identificación");
          } else if (
            (this.form.selectedtipoident === "26" ||
              this.form.selectedtipoident === "1") &&
            this.form.nombre === ""
          ) {
            swal("Debe ingresar un nombre o razón social");
          } else if (
            (this.form.selectedtipoident === "10" ||
              this.form.selectedtipoident === "36" ||
              this.form.selectedtipoident === "11") &&
            (this.form.nombre1 === "" || this.form.nombre3 === "")
          ) {
            swal("Debe ingresar un nombre y un apellido");
          } else if (this.form.identificacion === "") {
            swal("Debe ingresar la identificación");
          } else if (
            (this.form.selectedtipoident === "26" ||
              this.form.selectedtipoident === "1") &&
            this.form.digito === ""
          ) {
            swal("Debe ingresar el digito de verificación");
          } else if (this.form.dircorrespondencia.trim() === "") {
            swal("Debe ingresar la dirección de correspondencia");
            this.form.dircorrespondencia = "";
          } else if (this.form.depart === "") {
            swal("Debe seleccionar el departamento de correspondencia");
          } else if (this.ciudadcompleta.value == undefined) {
            swal("Debe seleccionar la ciudad de correspondencia");
          } else if (this.form.selectedcorreo === "") {
            swal(
              "Debe seleccionar si o no autoriza la notificación por correo"
            );
          } else if (
            this.form.selectedcorreo === "1" &&
            this.form.correo === ""
          ) {
            swal("Por favor ingresar un correo");
          } else if (
            this.form.selectedcorreo === "1" &&
            this.validEmail(this.form.correo) === false
          ) {
            swal("Por favor ingresar un correo valido");
          } else if (
            this.form.selectedcorreo === "2" &&
            this.form.correodirnoti === ""
          ) {
            swal("Por favor ingresar una dirección de notificación");
          } else if (this.form.observacionradicado === "") {
            swal("Debe ingresar una breve descripción de la solicitud");
          } else if (this.form.direccionpredio === "") {
            swal(
              "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
            );
          } else if (
            this.form.direccionpredio !== "" &&
            this.validaAlfanumericosespeciales(this.form.direccionpredio) ===
              false
          ) {
            swal(
              "Por favor validar la dirección ingresada solo se permite numeros letras y los caracteres .,#-/()"
            );
          } else if (this.form.departpredio === "") {
            swal(
              "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
            );
          } else if (this.form.ciudadpredio === "") {
            swal(
              "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
            );
          } else if (this.form.aceptacion === "") {
            swal(
              "Debe aceptar el uso de mis datos personales, tratamiento, protección"
            );
          } else {
            return true;
          }
        } else if (this.form.selectedanonimo == 1) {
          // SI es anonimo
          if (this.form.direccionpredio === "") {
            swal(
              "Debe ingresar la dirección del predio para el cual esta haciendo la solicitud"
            );
          } else if (
            this.form.direccionpredio !== "" &&
            this.validaAlfanumericosespeciales(this.form.direccionpredio) ===
              false
          ) {
            swal(
              "Por favor validar la dirección ingresada solo se permite numeros letras y los caracteres .,#-/()"
            );
          } else if (this.form.departpredio === "") {
            swal(
              "Debe seleccionar el departamento del predio para el cual esta haciendo la solicitud"
            );
          } else if (this.form.ciudadpredio === "") {
            swal(
              "Debe seleccionar el municipio del predio para el cual esta haciendo la solicitud"
            );
          } else if (this.status === "accepted" && this.form.correo1 === "") {
            swal("Por favor ingresar un correo");
          } else if (
            this.status === "accepted" &&
            this.validEmail(this.form.correo1) === false
          ) {
            swal("Por favor ingresar un correo valido");
          } else if (this.form.observacionradicado === "") {
            swal("Debe ingresar una breve descripción de la denuncia");
          } else if (this.form.aceptacion === "") {
            swal(
              "Debe aceptar el uso de mis datos personales, tratamiento, protección"
            );
          } else {
            return true;
          }
        }
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email);
    },
    validaAlfanumericosespeciales(dir) {
      var regex = new RegExp("^[a-zA-Z0-9 .,#-/()]+$");
      if (!regex.test(dir)) {
        return false;
      }
    },
    onChangeCiudad() {
      if (this.form.depart !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.depart}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudad = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeCiudadapoderado() {
      if (this.form.departapoderado !== "") {
        this.optionsciudades = [];

        this.$http
          .post(rutaciudad + `${this.form.departapoderado}`)
          .then((response) => {
            for (var i in response.data) {
              var value = response.data[i].idciudad;
              var text = response.data[i].descciudad;

              var objeto = {
                value: value,
                text: text,
              };
              this.optionsciudades.push(objeto);
            }
            this.showciudadapo = true;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onChangeRadioButton() {
      // Hacer algo basado en el valor seleccionado
      if (this.form.selectedtipoper === "1") {
        // Se seleccionó "Natural"
        console.log("Se seleccionó Natural");
        this.form.selectedtipoident = "";
        this.form.selectedtipoperjuridica = "";
        // Ejecuta tu código aquí
      } else if (this.form.selectedtipoper === "2") {
        // Se seleccionó "Jurídica"
        this.form.selectedtipoident = "";
        console.log("Se seleccionó Jurídica");
        // Ejecuta tu código aquí
      }
    },
    replaceSpecialCharacters(value) {
      if (typeof value === "string") {
        // Reemplaza el carácter "&" y comillas simples por una cadena vacía
        return value.replace(/&/g, "").replace(/'/g, "");
      } else {
        return value; // Si no es una cadena, devuelve el valor sin cambios
      }
    },
  },
  mounted() {
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    /*this.datos = this.$route.params;
    this.form.tipotramite = this.datos.tipopeticion;
    this.form.asunto = this.datos.nombretramite;
    this.form.selectedtipotram = this.datos.peticion;
    console.log("this.datos", Object.keys(this.datos).length);
    console.log("this.datos", this.datos.nombretramite);
    if (Object.keys(this.datos).length === 0) {
      this.$router.push({ name: "inicio" });
    }

     if (this.datos.nombretramite === "DENUNCIA POR CORRUPCIÓN") {
      this.showbloquedatos = true;
    }

    console.log("this.showbloquedatos", this.showbloquedatos);*/

    if (this.form.tipotramite == 90) {
      this.showanonimo = true;
    } else {
      this.form.selectedanonimo = 2;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
